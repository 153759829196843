import { Box, IconButton, Image, Link, Text } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import NextLink from "next/link";

type FooterProps = React.PropsWithChildren<{
  centered?: Boolean;
}>;

const Footer: React.FC<FooterProps> = ({ children, centered }) => {
  return (
    <>
      <Box mt="20" mb="20" px="10" maxWidth="1280px" mx="auto">
        <Box
          pos="relative"
          background="white"
          border="3px solid"
          borderColor="#222"
          borderRadius="12"
          _after={{
            content: '""',
            display: "block",
            zIndex: -1,
            position: "absolute",
            left: "-20px",
            bottom: "-30px",
            width: "100%",
            height: "100%",
            borderRadius: "12",
            backgroundAttachment: "fixed",
            backgroundColor: "#9441ff",
            backgroundImage: "/images/purple-sprinkles.svg",
            backgroundPosition: "top left",
            backgroundSize: "159px 200px",
          }}
        >
          <Box px={{ base: "4", md: "14" }} py={{ base: "7", md: "10" }}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Text mt="2" mr="1" lineHeight="100%">
                Created by
              </Text>
              <Link href="https://www.threefivetwo.com" target="_blank">
                <Image
                  src="/images/threefivetwo.svg"
                  alt="Three Five Two"
                  width="118"
                  height="21"
                />
              </Link>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="5"
            >
              <IconButton
                as={NextLink}
                href="https://www.linkedin.com/company/threefivetwo/"
                target="_blank"
                colorScheme="yellow"
                aria-label="LinkedIn"
                icon={<ReactSVG src="/images/icons/linkedin.svg" />}
                fontSize="20px"
                borderRadius="50%"
                mx="2"
              />
              <IconButton
                as={NextLink}
                href="https://twitter.com/threefivetwoinc"
                target="_blank"
                colorScheme="yellow"
                aria-label="Twitter"
                icon={<ReactSVG src="/images/icons/twitter.svg" />}
                fontSize="20px"
                borderRadius="50%"
                mx="2"
              />
              <IconButton
                as={NextLink}
                href="https://www.facebook.com/threefivetwoinc/"
                target="_blank"
                colorScheme="yellow"
                aria-label="Facebook"
                icon={
                  <ReactSVG
                    src="/images/icons/facebook.svg"
                    style={{ position: "relative", top: "4px" }}
                  />
                }
                fontSize="20px"
                borderRadius="50%"
                mx="2"
              />
            </Box>
            <Text align="center" mt="5">
              To make your next billion-dollar business, you have to create
              hockey-stick growth. But first, you have to build a killer
              product. And you can't do that until you solve the right problem.
              At 352, we bring experience and rigor to this process, to get you
              further, faster.
            </Text>
          </Box>
          <Box
            borderTop="3px solid"
            borderColor="gray.800"
            px="6"
            py="5"
            display={{ lg: "flex" }}
            alignItems="center"
            justifyContent="space-between"
            pos="relative"
          >
            <Box
              display="flex"
              justifyContent="center"
              order={{ lg: "2" }}
              pos={{ lg: "absolute" }}
              left={{ lg: "50%" }}
              top={{ lg: "50%" }}
              transform={{ lg: "translate(-50%, -50%)" }}
            >
              <Image
                src="/images/icons/praiprai.svg"
                alt="Smiley"
                width="8"
                height="8"
                mx="2"
              />
              <Image
                src="/images/icons/heart.svg"
                alt="Smiley"
                width="8"
                height="8"
                mx="2"
              />
              <Image
                src="/images/icons/smiley.svg"
                alt="Smiley"
                width="8"
                height="8"
                mx="2"
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              mt={{ baseStyle: "6", lg: "0" }}
              order={{ lg: "1" }}
            >
              <Link
                as={NextLink}
                href="/privacy"
                fontWeight="bold"
                fontSize="sm"
                mx="2"
              >
                Privacy Policy
              </Link>
              <Link
                as={NextLink}
                href="/support"
                fontWeight="bold"
                fontSize="sm"
                mx="2"
              >
                Support
              </Link>
            </Box>
            <Text
              align="center"
              fontSize="sm"
              mt={{ baseStyle: "5", lg: "0" }}
              order={{ lg: "3" }}
            >
              &copy;2023{" "}
              <Link href="https://www.threefivetwo.com" target="_blank">
                Three Five Two, Inc.
              </Link>{" "}
              All rights reserved.
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
