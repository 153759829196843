import Head from "next/head";
import NextLink from "next/link";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import DancingPanda from "@/components/DancingPanda";
import Footer from "@/components/Footer";
import { FaSlack } from "react-icons/fa";
import PraiseFeature from "@/components/PraiseFeature";
import StatsFeature from "@/components/StatsFeature";
import DrawingFeature from "@/components/DrawingFeature";
import { SLACK_INSTALL_URL } from "@/lib/constants";

export default function Home() {
  return (
    <>
      <Head>
        <title>Praisely - Pandarific Praise</title>
      </Head>
      <Box>
        <Box p={{ base: "4", sm: "10" }} maxWidth="1280px" margin="0 auto">
          <Box
            as="header"
            mb="16"
            display="flex"
            justifyContent="space-between"
          >
            <Link href="/">
              <ReactSVG src="/images/PraiselyLogoMark.svg" />
            </Link>

            <Grid
              display={{ base: "none", md: "grid" }}
              templateColumns="repeat(3, auto)"
              gap={{ base: "6", lg: "10" }}
              alignItems="center"
            >
              <Link fontWeight="bold" href="#overview">
                Overview
              </Link>
              <Link fontWeight="bold" href="#features">
                Features
              </Link>
              <Link fontWeight="bold" href="#faqs">
                FAQs
              </Link>
            </Grid>

            <Button
              leftIcon={<FaSlack size="20px" />}
              colorScheme="yellow"
              as={NextLink}
              href={SLACK_INSTALL_URL}
            >
              Add to Slack
            </Button>
          </Box>

          <Box>
            <Heading textAlign="center" fontSize="5xl">
              Create a happier workplace
            </Heading>
            <Text textAlign="center" fontSize="xl" maxW="600" mx="auto" mt="4">
              Bring your teams together with a fun way to publicly recognize and
              praise each other on{" "}
              <Text
                as="span"
                fontWeight="bold"
                display="inline-flex"
                alignItems="center"
                lineHeight="1"
                pos="relative"
                top="5px"
              >
                <Image
                  src="/images/icons/slack.svg"
                  alt="Slack"
                  height="24px"
                  width="25px"
                />{" "}
                <Text as="span">&nbsp;Slack</Text>
              </Text>
              !
            </Text>
            <Box marginX="auto" maxW="1150px" mt="10">
              <DancingPanda />
            </Box>
          </Box>

          <Box mt="28">
            <Heading textAlign="center" fontSize="4xl" id="overview">
              Platform for sharing wins company-wide
            </Heading>
            <Grid templateColumns={{ md: "repeat(3, 1fr)" }} gap={10} mt="14">
              <Box>
                <Box h="98px">
                  <Image
                    src="/images/icons/prSnap.svg"
                    alt="Fingers Snapping"
                    mx="auto"
                  />
                </Box>
                <Heading textAlign="center" fontSize="2xl" mt="5">
                  Setup is easy peasy
                </Heading>
                <Text textAlign="center" mt="5">
                  Add Praisely to your existing Slack workspace just by using
                  your Slack log-in.
                </Text>
              </Box>
              <Box>
                <Box h="98px">
                  <Image
                    src="/images/icons/prHat.svg"
                    alt="Party Hat"
                    mx="auto"
                  />
                </Box>
                <Heading textAlign="center" fontSize="2xl" mt="5">
                  Anyone can do it
                </Heading>
                <Text textAlign="center" mt="5">
                  With a simple command and a few kind words, any employee can
                  highlight a coworker’s achievements.
                </Text>
              </Box>
              <Box>
                <Box h="98px">
                  <Image
                    src="/images/icons/prSignFree.svg"
                    alt="FREE sign"
                    mx="auto"
                  />
                </Box>
                <Heading textAlign="center" fontSize="2xl" mt="5">
                  Happiness on the house
                </Heading>
                <Text textAlign="center" mt="5">
                  No subscription, no fees, no advertisements, just love.
                </Text>
              </Box>
            </Grid>
          </Box>

          <Heading textAlign="center" fontSize="5xl" mt="40" id="features">
            Great teams celebrate each other
          </Heading>
          <Grid
            templateColumns={{ md: "repeat(2, 1fr)" }}
            gap={16}
            mt="24"
            pos="relative"
            alignItems="center"
            zIndex="3"
          >
            <GridItem>
              <Heading fontSize="4xl">Get Praising</Heading>
              <Text mt="5" fontSize="xl">
                Your people do great things every day. Give them a platform to
                share those wins.
              </Text>
              <Text mt="5" fontSize="xl">
                Teams use /praise to recognize someone for a job well done.
                Praisely announces the praise and encourages others to
                &ldquo;+1&rdquo; for additional merit.
              </Text>
            </GridItem>
            <GridItem>
              <PraiseFeature />
            </GridItem>
          </Grid>
          <Grid
            templateColumns={{ md: "repeat(2, 1fr)" }}
            gap={16}
            mt="32"
            pos="relative"
            alignItems="center"
            zIndex="3"
          >
            <GridItem gridRow={{ md: 1 }} gridColumn={{ md: 2 }}>
              <Heading fontSize="4xl">Check for Stats</Heading>
              <Text mt="5" fontSize="xl">
                Each team member has a personal Panda Board with highlights
                including how many praises they have sent, their most recent
                praise, and their most popular praise. Full stats and praises
                from across your team can also be exported.
              </Text>
            </GridItem>
            <GridItem gridRow={{ md: 1 }}>
              <StatsFeature />
            </GridItem>
          </Grid>
          <Grid
            templateColumns={{ md: "repeat(2, 1fr)" }}
            gap={16}
            mt="24"
            pos="relative"
            alignItems="center"
            zIndex="3"
          >
            <GridItem>
              <Heading fontSize="4xl">Draw for Prizes</Heading>
              <Text mt="5" fontSize="xl">
                Take the love one step further with a random praise drawing!
                Select the timeframe and eligible users. Praisely creates a
                custom, interactive experience to select the winners.
              </Text>
            </GridItem>
            <GridItem>
              <DrawingFeature />
            </GridItem>
          </Grid>

          <Box
            mt="40"
            pos="relative"
            _after={{
              content: '""',
              display: "block",
              zIndex: -1,
              position: "absolute",
              width: "100vw",
              height: "100vh",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundImage: "/images/shortSquiggle.svg",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <Heading textAlign="center" fontSize="5xl" id="faqs">
              FAQs
            </Heading>
            <Accordion allowToggle maxW="840" mx="auto" mt="14" minH="460">
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Is there a limit to how many team members can use
                      Praisely?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  Nope. Once Praisely has been added to Slack, every member of
                  your Slack workspace will have access.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      How do I see all of the praise happening within my
                      company?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  This is where the export feature comes in handy. From your
                  Panda Board, tap the Export button, select the time frame you
                  are interested in and Praisely will generate a CSV file for
                  you with dates, praisers, recipients, messages, and a count of
                  plus ones.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Can you praise more than one person at a time?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  Absolutely! Why hold back when you have an entire team of
                  praiseworthy folks to celebrate? Tag as many people as you
                  would like in your praise. They will each receive a
                  notification that they have been praised.
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      What if I have an idea for making Praisely even better?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  Yes please! Drop us a note at{" "}
                  <Link
                    as={NextLink}
                    variant="highlight"
                    href="mailto:praisely@threefivetwo.com"
                  >
                    praisely@threefivetwo.com
                  </Link>
                  .
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Why do pandas have 6 toes?
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  According to the WWF, pandas have five fingers plus an
                  opposable pseudo-thumb (actually an enlarged wrist bone) that
                  they use to hold bamboo while eating. Must come in handy since
                  they have to eat upwards of 30 pounds of bamboo a day just to
                  stay full!
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
}
