const { SLACK_CLIENT_ID, APP_URI } = process.env;

export const SLACK_SCOPES = [
  "chat:write",
  "commands",
  "reactions:read",
  "team:read",
  "usergroups:read",
  "users:read",
  "emoji:read",
  "im:history",
];

export const SLACK_OAUTH_REDIRECT_URI = `${APP_URI}/api/slack/oauth_redirect/`;
export const SLACK_INSTALL_URL = `https://slack.com/oauth/v2/authorize?scope=${SLACK_SCOPES.join(',')}&client_id=${SLACK_CLIENT_ID}&redirect_uri=${SLACK_OAUTH_REDIRECT_URI}`;
export const getSlackInstallURL = (teamId: string) =>
  `${SLACK_INSTALL_URL}&install_redirect=update-to-granular-scopes&team=${teamId}`;
export const TWEMOJI_CDN = "https://twemoji.maxcdn.com/v/13.1.0/";
export const SLACK_EMOJI_CDN = "https://emoji.slack-edge.com/";